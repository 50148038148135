import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Accordion from "~/components/accordion";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>In this lesson, you have learned some ways that marketers try to persuade people to buy or use products or to believe in their causes. You have also practiced your critical thinking skills to analyze information.</p>

              <p>Open the rubric you have been working on. You will now work on the category "Lesson 3 Persuasion and Relevance".</p>

              <p>Work with your teammates to add statements to your rubric that relate to what you have learned in this lesson.</p>

              <Accordion
                headerText="Hint"
                open={false}
              >
                <p className="mt-0">You may not need all the spaces in the rubric. It is OK if you leave some blank. Keep in mind that you can make changes to any category of your rubric at any time. If you have new ideas to add to the earlier categories, you can add them now or change things you added earlier.</p>
              </Accordion>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students get their rubrics out. In this step, they will add criteria to the category "Lesson 3 Persuasion and Relevance". Students should feel free to discuss with their teammates. It is OK if students do not have as many criteria to list as there are spaces on the rubric. Some of the things that came up during the persuasion strategies activity may overlap with things that are already on their rubric from Lesson 2. Students can judge where to place things on the rubric or they may see differences that they feel justify inclusion on the rubric in more than one lesson.</p>

              <p>If students are having difficulty writing criteria, hold a class discussion to share and help prompt ideas. A couple of statements that would be relevant examples include the following:</p>

              <Unordered>
                <li>The website or advertisement does not rely on questionable persuasion methods.</li>
                <li>The information presented relates directly to the claims made.</li>
              </Unordered>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
